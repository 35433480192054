$(document).ready(function() {
	$('.js-field-niveau-scolaire select, .js-field-nom-ecole input, .js-field-prenom-parent input, .js-field-nom-parent input').prop('required', false);
	$('.js-field-client-age input').change(function() {
		if($('.js-field-client-age input:checked').val() == 'Enfant') {
			$('.js-field-niveau-scolaire, .js-field-nom-ecole, .js-field-prenom-parent, .js-field-nom-parent').removeClass('hide');
			$('.js-field-niveau-scolaire select, .js-field-nom-ecole input, .js-field-prenom-parent input, .js-field-nom-parent input').prop('required', true);
		} else {
			$('.js-field-niveau-scolaire, .js-field-nom-ecole, .js-field-prenom-parent, .js-field-nom-parent').addClass('hide'); 
			$('.js-field-niveau-scolaire select, .js-field-nom-ecole input, .js-field-prenom-parent input, .js-field-nom-parent input').prop('required', false);
		}
	});
	$('#type-de-service').on('change', function() {
		var specialite = $(this).find('option:selected').val();
		$.ajax({
        	type: 'POST',
	        url: '/wp-admin/admin-ajax.php',
	        dataType: "html", 
	        data: { action : 'get_ajax_posts', specialite : specialite },
	        success: function( response ) {
	            $('#specialiste').html( response ); 
	        }
	    });
	});
});