$(document).ready(function() {
  $('.banner-slider').slick({
  	 arrows: true,
  	 autoplay: true,
     autoplaySpeed: 2000,
  	 dots: true,
  	 infinite: true,
  	 speed: 2000,
	 fade: true,
	 cssEase: 'linear'
  });	
});
